declare let __MAPBOX_TOKEN__: string

import type { Feature, FeatureCollection, Geometry } from 'geojson';

import ComponentsMap from 'browser/components'
import { Link } from 'react-router-dom'
import { Status, LocationType as Type, Geolocation } from "shared-libs/generated/server-types/entity/yms/spotEntity"
import { Point, Polygon } from 'geojson';
import { addMetersToLngLat } from 'viewport-mercator-project';
import { vec2 } from 'gl-matrix';
import React from "react";
import { Viewport } from 'react-map-gl';
import MapGL, { Source, Layer, Popup } from '@urbica/react-map-gl'
import { IBaseProps } from "../base-props";
import { Entity } from "shared-libs/models/entity";
import { SchemaUris } from "shared-libs/models/schema";
import _ from 'lodash';
import { EntityDataSource } from '../../organisms/entity/entity-data-source';
import { Button, Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { EntityPreview } from '../../organisms/entity/entity-preview/entity-preview';
import classNames from 'classnames';

const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-v9'
const LIGHT_STYLE = 'mapbox://styles/mapbox/light-v9'

const SPOT_WIDTH_KM: number = .003048; //10ft
const SPOT_LONG_LENGTH_KM: number = .02286; //55ft
const SPOT_SHORT_LENGTH_KM: number = .016764; //75ft

const SPOT_HALF_WIDTH_KM: number = SPOT_WIDTH_KM / 2;
const SPOT_HALF_LONG_LENGTH_KM: number = SPOT_LONG_LENGTH_KM / 2;
const SPOT_HALF_SHORT_LENGTH_KM: number = SPOT_SHORT_LENGTH_KM / 2;

/**
 * Offsets for a rectangle representing a truck parking space. The first and last points are the
 * same to close the polygon.
 */
const SPOT_RECT_METER_OFFSETS = [
  vec2.fromValues(-SPOT_HALF_WIDTH_KM * 1000, -SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues(-SPOT_HALF_WIDTH_KM * 1000,  SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues( SPOT_HALF_WIDTH_KM * 1000,  SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues( SPOT_HALF_WIDTH_KM * 1000, -SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues(-SPOT_HALF_WIDTH_KM * 1000, -SPOT_HALF_SHORT_LENGTH_KM * 1000),
]

/**
 * Determines what properties to generate for a Layer element rendering spots based on spot type.
 */
const LAYER_PROP_GENERATORS: Partial<Record<Type, (color: string) => any>> = {
  [Type.DOOR]: genPointLayerProperties,
  [Type.SPOT]: genRectangleLayerProperties,
}

/**
 * Determines how to convert a spot to a GeoJSON Feature based on spot type. The Feature will be
 * provided by a Source element to a Layer element for rendering.
 */
const GEOMETRY_GENERATORS = {
  [Type.DOOR]: genPointFeature,
  [Type.SPOT]: genRectangleFeature,
}

const enum Availability {
  DISABLED = "disabled",
  FULL = "full",
  PARTIAL = "partial",
  EMPTY = "empty",
}

const AVAILABILITY_COLORS = {
  [Availability.DISABLED]: '#808080',
  [Availability.FULL]: '#FF0000',
  [Availability.PARTIAL]: '#FFFF00',
  [Availability.EMPTY]: '#00FF00',
}

const STATUS_AVAILABILITY_MAP = {
  [Status.FULL]: Availability.FULL,
  [Status.PARTIAL]: Availability.PARTIAL,
  [Status.OPEN]: Availability.EMPTY,
}

export interface IYardMapProps extends IBaseProps {
  entity: Entity
  facility?: Entity
}

type ColorMap = Partial<Record<Availability, string>>

export interface IYardMapState {
  spots: Entity[]
  style: string
  viewport: {
    latitude: number
    longitude: number
    zoom: number
  }
  selectedFeature?: FeatureProperties
  hoverInfo: {
    lng: number
    lat: number
  }
  colors?: ColorMap
}

type FeatureProperties = Geolocation & {
  name: string
  spotId: string
  currentTrailers: Entity[]
  textHeadingDegrees: number
}

export class YardMap extends React.Component<IYardMapProps, IYardMapState> {

  private entityDataSet: EntityDataSource;

  constructor(props) {
    super(props);

    const { entity } = this.props
    
    const facilityUuid = entity.get('core_yms_yardView.facility.entityId')
    const providedColors = entity.get('core_yms_yardView.colors')

    this.loadSpots(facilityUuid);

    const geolocation: Geolocation = _.get(entity, [
      'core_yms_yardView',
      'facility',
      'denormalizedProperties',
      'location.address',
      'geolocation',
    ])

    this.state = {
      spots: [],
      style: LIGHT_STYLE,
      viewport: {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
        zoom: 16
      },
      hoverInfo: null,
      colors: providedColors
    }
  }

  private loadSpots = async (facilityUuid: string) => {
    //TODO: add exists filter on geolocation
    const filters = [
      {
        path: 'core_yms_spot.facility',
        type: 'matchEdge',
        value: {
          entityId: facilityUuid,
        },
      }
    ]

    this.entityDataSet = new EntityDataSource({
      entityType: SchemaUris.SPOT_TYPE,
      filters: filters,
      metadata: { size: 100 },
      refreshInterval: 5000,
      enabledPrefetch: true
    }).setOnChange(this.handleOnDataSetChange)
  }

  private onMouseClick = (event) => {
    const { lngLat } = event;
    this.setState({
      hoverInfo: lngLat
    })
  }

  public componentDidMount() {
    this.entityDataSet.find()
  }

  public componentWillUnmount() {
    this.entityDataSet.dispose()
  }

  private handleOnDataSetChange = (spots) => {
    const spotsWithLocations = _.map(spots, (entity) => entity.data)
      .filter((spot) => Boolean(getGeolocation(spot)));

    this.setState({ spots: spotsWithLocations });
  }

  private handleFeatureClick = (event) => {
    if (!event.features || event.features.length == 0) {
      this.handleClosePopup(event)
      return
    }

    const feature: Feature<Geometry, FeatureProperties> = event.features[0]
    const { properties, geometry } = feature

    this.setState({
      selectedFeature: properties
    })
  }

  private handleClosePopup = (event) => {
    this.setState({
      selectedFeature: undefined
    })
  }

  public render() {
    const { style, spots, viewport, hoverInfo, colors, selectedFeature } = this.state;

    const spotsByType = _.groupBy(spots, getType);
    const spotLayers: JSX.Element[][] = Object.entries(spotsByType)
      .map(([type, spots]) => this.renderSpots(type as Type, spots, colors));
    const flattenedLayers: JSX.Element[] = _.flatMap(spotLayers);

    const hasSelection = selectedFeature && selectedFeature.latitude && selectedFeature.longitude
    const selectedSpot = hasSelection ? spots.find((spot) => spot.uniqueId === selectedFeature.spotId) : null;

    return (
      <MapGL
        className="w-100 h-100"
        {...viewport}
        mapStyle={style}
        accessToken={__MAPBOX_TOKEN__}
        onViewportChange={(viewport: Viewport) => this.setState({ viewport })}
        onMousedown={this.onMouseClick}
      >
        {hasSelection && this.renderPopover(selectedSpot)}
        {flattenedLayers}
        {this.renderButtonRow()}
      </MapGL>
    )
  }

  private renderPopover = (spot) => {
    if (!spot) {
      return null
    }

    const trailers = getCurrentTrailers(spot)
    const { latitude, longitude } = getGeolocation(spot)
    const hasTrailers = trailers && trailers.length > 0

    return (
      <Popup
        latitude={latitude}
        longitude={longitude}
        closeButton={true}
        closeOnClick={false}
        anchor='bottom'
        onClose={this.handleClosePopup}
      > 
        <div>
          <Link
            to={`/entity/${spot.uniqueId}`}>
              {`${getName(spot)} - ${getType(spot)}`}
          </Link>
          {hasTrailers && this.renderPopoverContents(trailers)}
        </div>
      </Popup>
    )
  }

  private renderPopoverContents = (trailers) => {
    return trailers.map((entity) => {
      return (
        <EntityPreview
          key={entity.trailer.entityId}
          value={entity.trailer}
          components={ComponentsMap}
          renderAsPopover={false}
          previewSchema='uiSchema.web.entityPreviewPopover'
        />
      )
    })
  }

  private renderButtonRow = () => {
    return (
      <div className={classNames('map-interface-button-row')}>
        {this.renderStyleToggle()}
      </div>
    );
  }

  private renderStyleToggle = () => {
    return (
      <Button
        className={classNames('map-interface-button', 'paper', Classes.BUTTON)}
        onClick={this.handleStyleToggle}
      >
        <Icon icon={IconNames.SATELLITE} />
      </Button>
    )
  }

  private handleStyleToggle = () => {
    this.setState((prevState) => ({
      style: prevState.style === SATELLITE_STYLE ? LIGHT_STYLE : SATELLITE_STYLE
    }))
  }

  /**
   * Converts spots of a specified type to a list of elements renderable by a parent MapGL element.
   * Each spot is rendered based on its type and its availability.
   *
   * Doors are rendered as points, and spots are rendered as rectangles. This association is
   * encoded in LAYER_PROP_GENERATORS and GEOMETRY_GENERATORS.
   *
   * Availability determines the spot's color, mapped in AVAILABILITY_COLORS.
   *
   * Each unique combination of spot type and availability requires its own Layer which is created
   * with render properties specific to that combination. Each Layer in turn requires its own Source
   * to provide the spots as GeoJSON features for rendering.
   * @param type the spot type to render
   * @param spots the spots to render
   * @returns list of Layers and their respective Sources for rendering spots of this type
   */
  private renderSpots = (type: Type, spots?: Entity[], colors?: ColorMap) => {
    if (!spots || spots.length == 0) {
      return null;
    }

    const spotsByAvailability = _.groupBy(spots, getAvailability);
    const layers = Object.entries(spotsByAvailability)
      .reduce((layers, [availability, spots]) => {
        layers.push(
          genSource(type, availability as Availability, spots),
          this.genShapeLayer(type, availability as Availability, colors),
          genTextLayer(type, availability as Availability));
        return layers;
      },
      []);

    console.log(type, layers);

    return layers;
  }

  /**
   * Generates a Layer element to render a set of spots. The spots are represented by a
   * FeatureCollection stored in a sibling Source element with the same id.
   * @param type the layer's spot type
   * @param availability the layer's spot availability
   * @returns the Layer that renders spots of this type and availability
   */
  private genShapeLayer = (type: Type, availability: Availability, colors?: ColorMap): JSX.Element => {
    const id = genId(type, availability);
    const color = (colors ?? AVAILABILITY_COLORS)[availability];
    const layerProperties = LAYER_PROP_GENERATORS[type](color);

    return <Layer
      key={`${id}-shapes`}
      id={`${id}-shapes`}
      source={id}
      onClick={this.handleFeatureClick}
      {...layerProperties}
    />
  }
}

/**
 * Generates a Layer element to render spot labels. The spots are represented by a FeatureCollection
 * stored in a sibling Source element with the same id.
 * @param type the layer's spot type
 * @param availability the layer's spot availability
 * @returns the Layer that renders text labels for spots of this type and availability
 */
function genTextLayer(type: Type, availability: Availability): JSX.Element {
  const id = genId(type, availability);

  return <Layer
    key={`${id}-labels`}
    type="symbol"
    id={`${id}-labels`}
    source={id}
    layout={{
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Regular'],
      'text-size': {
        stops: [
          [16.5, 3],
          [18, 10],
          [20, 18]
        ],
        base: 1,
      },
      'text-allow-overlap': false,
      'symbol-spacing': 1,
      'text-padding': 0,
      'icon-pitch-alignment': 'map',
      'text-pitch-alignment': 'map',
      'text-rotation-alignment': 'map',
      ...genTextLayerProps(type)
    }}
    paint={{
      'text-color': '#000000'
    }}
  />
}

function genTextLayerProps(type: Type): Record<string, any> {
  switch (type) {
    case Type.SPOT:
      return {
        'text-anchor': 'center',
        'text-rotate': ['get', 'textHeadingDegrees'],
      }
    case Type.DOOR:
      return {
        'text-offset': [.5, .25],
        'text-anchor': 'bottom-left',
        'text-rotate': 315,
      }
  }
}

/**
 * Generates a Source element to use as a data set of GeoJSON features.
 * @param type the spot type to generate the Source element for
 * @param availability the availability of the spots
 * @param spots the generated Source's spot data
 * @returns a Source element providing the spots as GeoJSON features
 */
function genSource(type: Type, availability: Availability, spots: Entity[]): JSX.Element {
  const id = genId(type, availability);

  return <Source
    key={`${id}-source`}
    id={id}
    type="geojson"
    data={genFeatureCollection(type, spots)}
  />
}

/**
 * Converts a set of spots to GeoJSON features appropriate to the spot type. Doors are converted to
 * points, and spots are converted to rectangles. This collection will be used as the data set for
 * the Source element, that in turn provides the features for rendering by a sibling Layer element
 * with the same id.
 * @param type the spot type to generate the collection for.
 * @param spots the spots to convert to features
 * @returns a feature collection to be added to a Source element
 */
function genFeatureCollection<G extends Geometry>(
  type: Type,
  spots: Entity[]
): FeatureCollection<G> {
  const features: Feature<G>[] = spots.map((spot) => {
    const { latitude, longitude, heading: headingDegrees = 0 } = getGeolocation(spot)
    const spotHeadingDegrees = normalizeAngleDegrees(headingDegrees)
    let textHeadingDegrees = spotHeadingDegrees - 90
    while (textHeadingDegrees >= 180) {
      textHeadingDegrees -= 180
    }

    //TODO fix this geolocation enumeration
    return {
      type: 'Feature',
      properties: {
        name: getName(spot),
        latitude: latitude,
        longitude: longitude,
        spotId: spot.uniqueId,
        currentTrailers: getCurrentTrailers(spot),
        textHeadingDegrees: textHeadingDegrees,
      },
      geometry: GEOMETRY_GENERATORS[type](spot)
    }
  });

  return {
    type: "FeatureCollection",
    features: features
  }
}

/**
 * Generate a GeoJSON polygon feature that represents a rectangle from a spot.
 * @param spot the spot
 * @returns the GeoJSON polygon feature
 */
function genRectangleFeature(spot: Entity): Polygon {
  const { longitude, latitude, heading: headingDegrees = 0 } = getGeolocation(spot);
  const headingDegreesNormalized = normalizeAngleDegrees(headingDegrees);

  let offsets = SPOT_RECT_METER_OFFSETS;
  if (headingDegreesNormalized != 0) {
    const headingRadians = (headingDegreesNormalized * Math.PI) / 180;
    offsets = offsets.map((offset) => {
      const rotated = vec2.create();
      vec2.rotate(rotated, offset, [0, 0], headingRadians);
      return rotated;
    });
  }

  const coordinates = offsets.map((offset: [number, number]) => {
    return addMetersToLngLat([longitude, latitude], offset);
  });

  return {
    type: 'Polygon',
    coordinates: [coordinates]
  };
}

/**
 * Normalizes an angle to an amount of degrees between 0 inclusive and 360 exclusive.
 * @param angle any amount of degrees
 * @returns an angle between 0 and 360 degrees that is rotationally equivalent to the input angle
 */
function normalizeAngleDegrees(angle: number): number {
  const normalized = angle % 360;
  return normalized > 0 ? normalized : normalized + 360;
}

/**
 * Generate the GeoJSON feature that represents a point from a spot.
 * @param spot the spot
 * @returns the GeoJSON point feature
 */
function genPointFeature(spot: Entity): Point {
  const { longitude, latitude } = getGeolocation(spot);
  return {
    type: 'Point',
    coordinates: [longitude, latitude]
  }
}

/**
 * Generates the Layer properties for a layer that renders points.
 * @param color the point color
 * @returns the point layer properties
 */
function genPointLayerProperties(color: string) {
  return {
    type: "circle",
    paint: {
      'circle-color': color,
      'circle-radius': 6,
      'circle-stroke-color': '#FFF'
    }
  }
}

/**
 * Generates the Layer properties for a layer that renders filled polygons.
 * @param color the rectangle color
 * @returns the rectangle layer properties
 */
function genRectangleLayerProperties(color: string) {
  return {
    type: "fill",
    paint: {
      'fill-color': color,
      'fill-outline-color': '#FFF'
    }
  }
}

/**
 * Generates an id for an element based on the type and availability of the spots the element is
 * rendering.
 */
function genId(type: Type, availability: Availability): string {
  return `${type}-${availability}`;
}

/**
 * Get the availability state of the spot.
 */
function getAvailability(spot: Entity): Availability {
  if (!isActive(spot)) {
    return Availability.DISABLED;
  }

  return STATUS_AVAILABILITY_MAP[getStatus(spot)]
}

function getCurrentTrailers(spot: Entity): Entity[] {
  return _.get(spot, ['core_yms_spot', 'currentTrailers'])
}

function getType(spot: Entity): Type {
  return _.get(spot, ['core_yms_spot', 'locationType'])
}

function getStatus(spot: Entity): Status {
  return _.get(spot, ['core_yms_spot', 'status'])
}

function getName(spot: Entity): string {
  return _.get(spot, ['core_yms_spot', 'name'])
}

function getGroup(spot: Entity): string {
  return _.get(spot, ['core_yms_spot', 'group'])
}

function getGeolocation(spot: Entity): Geolocation {
  return _.get(spot, ['core_yms_spot', 'geolocation'])
}

function isActive(spot: Entity): boolean {
  return _.get(spot, ['core_yms_spot', 'isActive'])
}
