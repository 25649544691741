import React from "react";

import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { Modal } from "browser/components/atomic-elements/atoms/modal";
import { InputField } from "browser/components/atomic-elements/molecules/fields/input-field/input-field";
import { Footer } from "browser/components/atomic-elements/atoms/footer/footer";
import { TextareaField } from "browser/components/atomic-elements/molecules/fields/textarea-field";
import { SelectField } from "browser/components/atomic-elements/molecules/fields/select-field";
import _ from "lodash";
import apis from "browser/app/models/apis";
import { EntityRenderer } from "shared-libs/components/entity/renderer";

import ComponentsMap from 'browser/components'

interface IActionInputModalProps {
  confirmationTitle: string
  confirmationText: string

  primaryButtonText: string
  cancelButtonText: string

  onClose: () => void
  onConfirm: (commentValue: string, diffToApply: any) => void

  variableMappings: { property: string} // LHS key, RHS getter value path
  sampleEvaluator: any
  requestComment: boolean
  commentTemplates: string[] // interpolatable strings

  editSchemaPath: string
  sampleEntity: any
}

interface IActionInputModalState {
  commentValue?: string

  entity: any
  entitySnapshot: any
}

export class ActionInputModal extends React.Component<IActionInputModalProps, IActionInputModalState> {
  public static defaultProps: Partial<IActionInputModalProps> = {
    cancelButtonText: 'Cancel',
    primaryButtonText: 'Confirm',
  }

  store: any

  constructor(props?: any) {
    super(props)

    this.store = apis.getStore()

    const lastMixin: any = _.last(props.sampleEntity.mixins.active)
    const schemaId = lastMixin.entityId
    const schema = this.store.getRecord(schemaId)

    const entity = this.store.createRecord(schema)
    const entitySnapshot = _.cloneDeep(entity.prevContent)

    this.state = {
      entity,
      entitySnapshot,
    }
  }

  public static open(props?: any, ref?: any) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <ActionInputModal {...props} ref={ref}/>
      </Modal>
    )
  }

  public render() {
    const { requestComment, editSchemaPath } = this.props

    const maybeAdditionalInputs = (requestComment || editSchemaPath) ? <div className="u-borderTop">
      {this.renderCommentInputs()}
      {this.renderGenericInputs()}
    </div> : null

    return <div>
      <div className='c-modalHeader'>
        <h4 className='c-modal-title u-textCenter'>
          {this.props.confirmationTitle}
        </h4>
      </div>
      <div className='c-modalBody u-textCenter'>
        {this.props.confirmationText}
        {maybeAdditionalInputs}
      </div>
      <Footer
        primaryButtonText={this.props.primaryButtonText}
        cancelButtonText={this.props.cancelButtonText}
        onCancelButtonClick={this.onCloseHandler}
        onPrimaryButtonClick={this.onConfirmHandler}
      />
    </div>
  }

  public renderCommentInputs() {
    const { commentTemplates, variableMappings, sampleEvaluator, requestComment } = this.props
    const { commentValue } = this.state

    if (!requestComment) {
      return null
    }

    return <>
      { commentTemplates &&
        <SelectField
          label='Templates'
          options={commentTemplates.map(x => ({ label: x, value: x })) }
          onChange={ commentValue => this.setState({ commentValue }) }
        />
      }
      <TextareaField
        label='Message'
        value={commentValue}
        minRows={3}
        onChange={ commentValue => this.setState({ commentValue }) }
      />
      { variableMappings &&
        <SelectField
          label='Inject Variable To Message'
          options={Object.keys(variableMappings).map(x => ({ label: x, value: x })) }
          onChange={this.handleInjectVariable}
        />
      }
      <TextareaField
        label='SAMPLE MESSAGE'
        value={sampleEvaluator(commentValue)}
        isDisabled={true}
        minRows={3}
      />
    </>
  }

  public handleInjectVariable = variableKey => {
    let { commentValue } = this.state

    if (_.isEmpty(commentValue)) {
      commentValue = ''
    } else {
      commentValue += ' '
    }
    commentValue += `$\{${variableKey}}`

    this.setState({ commentValue })
  }

  public renderGenericInputs() {
    const { editSchemaPath } = this.props
    const { entity } = this.state

    if (_.isEmpty(editSchemaPath)) {
      return null
    }

    return <>
      <EntityRenderer
        componentsMap={ComponentsMap}
        uiSchemaPath={editSchemaPath}
        value={ entity }
        state={ {
          settings: apis.getSettings(),
        }}
      />
    </>
  }

  public onCloseHandler = () => {
    const { onClose } = this.props

    onClose()
  }

  public onConfirmHandler = () => {
    const { onConfirm, onClose } = this.props
    const { commentValue } = this.state

    const { entity, entitySnapshot } = this.state
    const diffToApply = entity.jsonPatch(entitySnapshot, entity.content, { transformOps: []})

    onConfirm(commentValue, diffToApply)
    onClose()
  }
}
