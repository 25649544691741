import React from 'react'
import _ from 'lodash'

import { EntityRenderer } from 'shared-libs/components/entity/renderer'

import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { followLink } from 'browser/app/utils/utils'
import { StoryboardTask } from 'shared-libs/models/types/storyboard/storyboard-task'
import { EventType } from 'shared-libs/models/types/storyboard/storyboard-execution'
import printJS from 'print-js'
import { Mappings } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { ErrorModal } from '../error-modal'

interface IStoryRendererProps {
  task: StoryboardTask
  currentRoute: any
  currentNode: any
  entity: any
  taskEntity: any

  navigateForwards: () => void
  navigateBackwards: () => void
  updateExecutionState: (mappings: Mappings, context: any) => void
  onChange: () => void
  actions: any
}

interface IStoryRendererState {}

export class StoryRenderer extends React.Component<IStoryRendererProps, IStoryRendererState> {
  static contextType = ComponentsContext
  context!: React.ContextType<typeof ComponentsContext>
  onEntityChange: any

  constructor(props) {
    super(props)

    this.onEntityChange = _.debounce(this._onEntityChange, 250)
  }

  public render() {
    const { task, taskEntity, actions } = this.props
    const isMobileWeb = this.context.platform === PlatformType.MOBILE_WEB

    if (_.includes([EventType.CREATE, EventType.EDIT, EventType.DETAIL], task.actionType)) {
      const taskUiSchema = task.uiSchema(this.context.platform)
      const settings = this.props.entity.api.settings

      return (
        <div>
          {!_.isEmpty(taskUiSchema) && (
            <EntityRenderer
              componentsMap={this.context.components}
              uiSchema={taskUiSchema}
              uiSchemaPath="ignoreMe"
              onChangeComplete={this.onEntityChange}
              value={taskEntity}
              actions={actions}
              state={{
                settings,
                actionHooks: this.getActionHooks(),
              }}
              context={
                isMobileWeb
                  ? {
                      density: 'collapse',
                      isFullScreen: true,
                      isHorizontalLayout: false,
                    }
                  : undefined
              }
            />
          )}
        </div>
      )
    }

    return null
  }

  private getActionHooks() {
    return {
      goNext: this.props.navigateForwards,
      goBack: this.props.navigateBackwards,
      print: this.handlePrint,
      updateExecutionState: this.props.updateExecutionState,
      openLink: followLink,
    }
  }

  private _onEntityChange = () => {
    this.props.onChange()
  }

  /**
   * Handle printing of a file url
   * @param fileUrl  file url
   * @param onClose a callback when the diaglog is closed
   */
  private handlePrint = (fileUrl: string, onClose: () => void) => {
    printJS({
      printable: fileUrl,
      onError: (error, xmlHttpRequest) => {
        ErrorModal.open({
          errorTitle: "Unable to print",
          errorText: error
        })
        console.error('Unable to print', error, xmlHttpRequest)
        onClose()
      },
      onPrintDialogClose: () => {
        onClose()
      }
    })
  }
}
